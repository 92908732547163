import React, {useState} from 'react';
import {routePaths} from '../../constants/routes';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {ModalLvl, ShareModal} from '../Groups/ShareModal';
import {useSendNotSentEmails} from '../../hooks/emails';
import {openNotifError} from '../Groups/View';

const StyledFooter = styled(Footer)<{$fixed?: boolean}>`
  ${({$fixed}) => ($fixed ? `position: fixed; bottom: 0; left: 0;` : `display: none;`)};
`;

type GroupFooterContainer = {
  onSuccess?: () => void;
  onBulk?: () => Promise<void>;
  tab?: string;
};

export const GroupFooterContainer: React.FC<GroupFooterContainer> = ({onSuccess, onBulk, tab}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [isShared, setIsShared] = useState(false);
  const {pathname} = useLocation();
  const isGroupPage = pathname.includes(routePaths.groupView);
  const id = isGroupPage ? pathname.split('/')?.[2] : '';
  const {send} = useSendNotSentEmails([id], openNotifError);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => {
    await send();
    await onBulk?.();
    onSuccess?.();
    setIsShared(true);
  };
  const showReshare = isGroupPage && !isShared;
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  if (tab === 'pdf') return null;
  return (
    <>
      <ShareModal modalLvl={modalLvl} setModalLvl={changeModalLvl} onSuccess={onShare} />
      <StyledFooter
        showFootNote
        showReshare={showReshare}
        buttonText={'Bulk Send Tickets'}
        $fixed={showReshare}
        onReshare={onShareClick}
      />
    </>
  );
};

const FooterContainer = () => {
  return null;
};
export default FooterContainer;
